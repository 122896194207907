* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif !important;
}

.padding_section{
    padding:50px 0;
}

section.banner {
    position: relative;
    height: 70vh;
}

section.banner video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}

section.banner .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

section.banner .container .banner-title {
    color: var(--color3);
    width: 600px;
    margin: auto;
}

section.banner .container .banner-title h1.title_text {
    color: var(--color3);
    font-size: 60px;
}


/* .banner-btn a.btn{
    text-decoration: none;
    font-size: 18px;
    border: 1px solid var(--color3);
    padding: 7px 28px;
    color: var(--color3);
    margin-top: 22px;
    border-radius: 0;
    transition: 0.3s;
}
.banner-btn a.btn:hover{
    background:#0000009a;
    border: 1px solid rgba(0, 0, 0, 0.603);
} */

/* ========== sections =========== */
h3.section_title {
    font-size: 45px;
    position: relative;
    margin-bottom: 30px;
    font-weight: 700;
    line-height: 44px;
}

h3.section_title span {
    font-weight: 300;
}

h3.section_title::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 2px;
    background: #fffc01;
    bottom: -6px;
}

h3.section_title::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 2px;
    background: #fffc01;
    bottom: -10px;
    left: 0;
}
h3.section_title.social::before{
    background: #006ebe;

}
h3.section_title.social::after{
    background: #006ebe;

}
h3.section_title.circular::before{
    background: var(--titleUnderLine);

}
h3.section_title.circular::after{
    background: var(--titleUnderLine);

}

section.sustain .container .row {
    align-items: center;
}

section.sustain .img-box {
    /* position: relative; */
    height: 600px;
}

section.sustain .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.sustain .container .content {
    padding-right: 50px;
}

section.circular .row {
    align-items: center;
}

section.circular.section_padding .container .img-box img {
    width: 100%;
}

section.Social .img-box {
    height: 600px;
}

section.Social .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.Social .row {
    align-items: center;
}

section.explore {
    position: relative;
}

section.explore .img-box {
    filter: brightness(0.3);
    -webkit-filter: brightness(0.3);
    height: 500px;
}

section.explore .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.explore .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 0 200px;
    font-size: 23px;
    text-align: center;
}


.banner-title .logo.mb-4 {
    margin-bottom: 2.5rem !important;
    padding: 0 25px;
}
.banner-title .logo.mb-4 img{
    width:100%;
}

/* ================= footer ================= */

footer {
    padding: 10px 0;
    background: #0166B1;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

footer ul li a {
    color: var(--color3);
    font-size: 14px;
}

footer ul li .icon {
    margin-right: 15px;
    fill: var(--color3);
}

footer ul li .icon svg {
    font-size: 26px;
    fill: var(--color3);
    transition: 0.3s;
    stroke: #006ebe;
    stroke-width: 1px;
}

footer .copyright {
    color: #97bbdb;
    font-size: 14px;
    text-align: end;
}

/* ====form css==== */
section.contact_us .row{
    align-items: center;
}
section.contact_us input {
    width: 100%;
    border: 1px solid #dee2e6;
    padding: 8px 20px;
    border-radius: 5px;
    margin-top: 6px;
}
section.contact_us input:focus{
    outline:none;
    border: 1px solid grey;
}
section.contact_us a.submit_btn {
    color: white;
    background: #0166b1;
    padding: 8px 35px;
    border: 1px solid #0166b1;
}
section.contact_us .col-md-12.button {
    text-align: center;
    margin-top: 35px;
}
.contact_us .form_img{
    height:550px;
}
.contact_us .form_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.row.form_row .col-md-6 div {
    margin-bottom: 30px;
}
.row.form_row .col-md-6 div label,
.row.form_row .col-md-12 div label {
    font-weight: 600;
}
.contact_us input[type="textarea"] {
    height: 100px;
}
button.submit_btn.btn.btn-primary {
    background: #0166b1 !important;
    border: 1px solid #0166B1 !important;
    box-shadow:none !important;
    width:30%;
}


/* ====== mobile responsive ====== */

@media (max-width:767px){
    .section_padding {
        padding: 0 !important;
    }
    h3.section_title {
        font-size: 35px;
        line-height: 34px;
        margin-top: 20px;
    }
    section.banner .container .banner-title {
        width: 100%;
    }
    section.banner .container .banner-title h1.title_text {
        font-size: 40px;
    }
    section.sustain .container .row,
    section.Social .container .row {
        flex-direction: column-reverse;
    }
    section.sustain .img-box,
    section.Social .img-box 
    {
        height: 350px;
        margin-top: 30px;
        transform: none !important;
    }
    section.explore .container {
        padding: 0 12px !important;
        font-size: 18px !important;
    }
    section.explore .container .row {
        width: 100% !important;
        margin: 0 auto !important;
    }
    footer .d-flex {
        justify-content: center !important;
    }
    footer .copyright {
        text-align: center;
    }
    section.explore {
        margin-top: 50px !important;
    }
    section.contact_us input {
        padding: 5px 20px;
    }
    .row.form_row .col-md-6 div {
        margin-bottom: 16px;
    }
    footer ul li .icon {
        margin-right: 3px !important;
    }
    #preloader #status {
        padding: 0 30px;
      }
      
      #preloader #status .logo img{
        width:100%;
      }
}
/* ====== tablet responsive ====== */

@media (min-width:768px) and (max-width:1024px){
    section.banner {
        position: relative;
        height: 40vh;
    }
    .col-md-6{
        width:100%;
    }
    .section_padding {
        padding: 0 !important;
    }
    h3.section_title {
        font-size: 35px;
        line-height: 34px;
        margin-top: 20px;
    }
    section.banner .container .banner-title {
        width: 100%;
    }
    section.banner .container .banner-title h1.title_text {
        font-size: 40px;
    }
    section.sustain .container .row,
    section.Social .container .row {
        flex-direction: column-reverse;
    }
    section.sustain .img-box,
    section.Social .img-box 
    {
        height: 350px;
        margin-top: 30px;
        transform: none !important;
    }
    section.explore .container {
        padding: 0 12px !important;
        font-size: 18px !important;
    }
    section.explore .container .row{
        width:100% !important;
    }
    section.explore .img-box {
        height: 350px;
    }
    footer .col-md-6{
        width: 50%;
    }
    section.explore {
        margin-top: 50px !important;
    }
}